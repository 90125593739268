exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-2023-answers-tsx": () => import("./../../../src/pages/products/2023_answers.tsx" /* webpackChunkName: "component---src-pages-products-2023-answers-tsx" */),
  "component---src-pages-products-2023-tsx": () => import("./../../../src/pages/products/2023.tsx" /* webpackChunkName: "component---src-pages-products-2023-tsx" */),
  "component---src-pages-products-2024-tsx": () => import("./../../../src/pages/products/2024.tsx" /* webpackChunkName: "component---src-pages-products-2024-tsx" */),
  "component---src-pages-products-2025-tsx": () => import("./../../../src/pages/products/2025.tsx" /* webpackChunkName: "component---src-pages-products-2025-tsx" */)
}

